<template>
    <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div class="container">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="product-detail">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade active show" id="product-4" role="tabpanel">
                                            <div class="product-img">
                                                <img src="https://kmcorporate.com/wp-content/uploads/2021/06/Chain-tre-quarti-rev.1-210610-1536x1044.jpg"
                                                    alt="img-4" class="img-fluid mx-auto d-block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end product img -->

                            </div>
                            <div class="col-xl-7">
                                <div class="mt-4">

                                    <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                                        MAIN FEATURES
                                    </h1>
                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        Feeding system: up to 10 different wires – Daisy chain IDC wiring with option of
                                        standard crimping on one side – Max. Number of connectors for harness: 7
                                        connectors , Min. 2 connectors – Connector Min. Pitch Length = 25 mm. – Max.
                                        Length of the full harness = 500 mm – Max. configuration of the harness: 10
                                        positions for 7 connectors Rast 2,5 – Connector loading system without machine
                                        stop – Terminating unit with fine adjustment termination height – Test device
                                        for the correct wire position in the connector (optional) – Selective destroying
                                        unit of bad harness and separation of bad harnesses (optional) – User friendly
                                        HMI based on Windows O.S. – ADSL internet connection for remote assistance – CE
                                        conformity
                                    </p>
                                </div>
                                <br />
                                <div class="mt-4">
                                    <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                                        CRIMP MODULE
                                    </h1>
                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        Max 1 Terminal Unit with CFA and Carrier strip chopper and Paper take-up device
                                        Wire Feeding up to 10 different colours Seal Station device (optional)
                                        Dimensions: 1300 x 1800 x h 2450 (safety shield open) Weight: 1000 kg.
                                    </p>
                                </div>
                                <br />



                                <p style="font-size: 14px; color: black; font-weight: 400">
                                    All data and measures are indicative and can be changed without notice. Certain
                                    types of extremely hard, tough wires may not be able to be processed even if written
                                    within the indicated ranges. In case of doubt, KM Corporate is willing to produce
                                    samples of your wire. </p>
                            </div>
                        </div>
                        <!-- end row -->
                        <br />
                        <br />
                        <br />
                        <div class="row">
                            <div class="col-5"></div>
                            <div class="col-7">
                                <button @click="file()" class="btn btn-primary" style="width:100%"><i
                                        class="fa fa-download"></i> Download The Technical Sheet</button>
                            </div>
                        </div>
                        <br><br><br>
                        <div class="row">
                            <h1 style="color: black; font-weight: 800; margin-left: 40px" class="font-size-20 mb-3">
                                Gallery

                            </h1>

                        </div>
                        <br />

                        <div class="row" style="margin-left:150px;">

                            <vue-picture-swipe :items="items"></vue-picture-swipe>
                        </div>
                        <!-- end row -->

                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
    components: {

        Header,
        Footer,


    },
    data() {
        return {
            items: [{
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/Chain-dallalto-rev.1-210610-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/Chain-dallalto-rev.1-210610-150x150.jpg',
                w: 1200,
                h: 900,
                alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/CHAIN-rev.1-210714-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/CHAIN-rev.1-210714-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/07/CHAIN-HMI-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/07/CHAIN-HMI-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-015-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-015-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-016-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-016-150x150.jpg',
                w: 1200,
                h: 900
            },

            ]
        };

    },

    methods: {
        file() {
            window.location.href = "https://kmcorporate.com/wp-content/uploads/2021/06/Scheda_Chain__Rev-19-01-Low.pdf";
        }
    }
};
</script>